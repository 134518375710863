import { AppBar } from '@material-ui/core';
import HiddenCss from '@material-ui/core/Hidden/HiddenCss';
import dynamic from 'next/dynamic';

import useStorefrontData from 'src/hooks/useStorefrontData';
import { useStyles } from 'src/views/Header/styled';
import { maybe } from 'src/core/utils';

const BottomMenu = dynamic(() => import('./components/BottomMenu'));
const MobileMenu = dynamic(() => import('./components/MobileMenu'));

export default function Header() {
  const classes = useStyles();
  const { shop, user } = useStorefrontData();
  const topMenu = maybe(() => shop.navigation.main, null);
  const bottomMenu = maybe(() => shop.navigation.secondary, null);

  return (
    <AppBar position="static" className={classes.root}>
      {/* // render all 3 types of header and then remove unneeded with JS */}
      {/* // because we cannot determine real screen resolution on SSR */}
      <>
        <HiddenCss smDown>
          <BottomMenu menu={bottomMenu} customer={user} />
        </HiddenCss>
        <HiddenCss mdUp>
          <MobileMenu
            topMenu={topMenu}
            bottomMenu={bottomMenu}
            customer={user}
          />
        </HiddenCss>
      </>
    </AppBar>
  );
}
