import { alpha, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
    color: theme.palette.common.white,
    borderBottom: `1px solid ${alpha(theme.palette.secondary.main, 0.75)}`,
    boxShadow: '0px 4px 3px rgba(23, 34, 39, 0.25)'
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  }
}));
