import {useApolloClient, useLazyQuery} from '@apollo/react-hooks';
import {useState} from 'react';

import {getCountryFromStorage, saveCountryToStorage} from '../core/utils';
import {
  GetPaymentMethodsQuery,
  PaymentCountriesQuery
} from '../components/PaymentMethods/queries';

// logic to load methods to speedup checkout page loading
export default function useCheckoutBoost() {
  const client = useApolloClient();
  const defaultCountry = getCountryFromStorage();
  const [country, setCountry] = useState(defaultCountry);

  const [loadPaymentCountries] = useLazyQuery(PaymentCountriesQuery, {
    onCompleted: data => {
      saveCountryToStorage(data.paymentCountries.userCountryCode);
      setCountry(data.paymentCountries.userCountryCode);
      loadPaymentMethods({
        variables: {
          filter: {
            country: data.paymentCountries.userCountryCode,
            onlyEnabledMethods: true
          }
        }
      });
    }
  });

  const [loadPaymentMethods] = useLazyQuery(GetPaymentMethodsQuery, {
    // TODO: test and maybe remove, country is already passed
    //  in loadPaymentCountries ->  loadPaymentMethods
    onCompleted: data => {
      client.cache.writeQuery({
        query: GetPaymentMethodsQuery,
        variables: {
          filter: {
            country: country,
            onlyEnabledMethods: true
          }
        },
        data: data
      });
    }
  });

  return loadPaymentCountries;
}
