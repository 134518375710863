import gql from 'graphql-tag';

import {CheckoutFragment} from 'src/views/CheckoutNext/queries';

export const CheckoutCreateMutation = gql`
  ${CheckoutFragment}
  mutation CheckoutCreate($input: CheckoutInput!) {
    checkoutCreate(input: $input) {
      errors {
        message
        field
      }
      created
      checkout {
        ...CheckoutFragment
      }
    }
  }
`;

export const CheckoutAddProductMutation = gql`
  ${CheckoutFragment}
  mutation CheckoutAddProduct($id: ID!, $input: CheckoutInput!) {
    checkoutAddProduct(id: $id, input: $input) {
      errors {
        message
        field
      }
      checkout {
        ...CheckoutFragment
      }
    }
  }
`;

export const CheckoutUpdateProductMutation = gql`
  ${CheckoutFragment}
  mutation CheckoutUpdateProduct($id: ID!, $input: CheckoutInput!) {
    checkoutUpdateProduct(id: $id, input: $input) {
      errors {
        message
        field
      }
      checkout {
        ...CheckoutFragment
      }
    }
  }
`;

export const CheckoutRemoveProductMutation = gql`
  ${CheckoutFragment}
  mutation CheckoutRemoveProduct($id: ID!, $input: CheckoutInput!) {
    checkoutRemoveProduct(id: $id, input: $input) {
      errors {
        message
        field
      }
      checkout {
        ...CheckoutFragment
      }
    }
  }
`;

export const CustomPaymentCheckoutCreateMutation = gql`
  ${CheckoutFragment}
  mutation CustomPaymentCheckoutCreate($input: CustomPaymentCheckoutInput!) {
    customPaymentCheckoutCreate(input: $input) {
      errors {
        message
        field
      }
      created
      checkout {
        ...CheckoutFragment
      }
    }
  }
`;

export const GoldCheckoutCreate = gql`
  ${CheckoutFragment}
  mutation goldCheckoutCreate($input: GoldCheckoutInput!) {
    goldCheckoutCreate(input: $input) {
      errors {
        message
        field
      }
      created
      checkout {
        ...CheckoutFragment
      }
    }
  }
`;

export const TrackQuickBuyWidgetMutation = gql`
  mutation TrackQuickBuyWidget($input: TrackQuickBuyWidgetInput!) {
    trackQuickBuyWidget(input: $input) {
      errors {
        message
        field
      }
    }
  }
`;
