import * as React from 'react';
import {useRouter} from 'next/router';
import Head from 'next/head';
import {NextSeo} from 'next-seo';
import {useMediaQuery} from '@material-ui/core';
import dynamic from 'next/dynamic';
import {ThemeProvider} from '@material-ui/core/styles';

import {PageContext} from 'src/context';
import theme from 'src/themes/theme';
import {changeZopimPage} from 'src/core/zopim';
import HeaderNext from 'src/views/HeaderNext';
import {themeNext} from 'src/themes/themeNext';

import useStorefrontData from '../../hooks/useStorefrontData';
import Loader from '../Loader';
import {PageSlugs} from '../../core/urls';
import {BreadcrumbSchema, FaqSchema, SchemaOrg} from './ShemaOrg';
import Header from '../../views/Header';

const defaultPageSeo = {
  title: 'Probemas',
  seoTitle: 'Probemas',
  seoDescription: 'Probemas',
  seoImage: {
    url: `${process.env.ORIGIN}/images/probemas_og_image.jpg`
  },
  slug: '/'
};

const Footer = dynamic(() => import('../../views/FooterNext'), {
  loading: () => <Loader />,
  ssr: false
});

const NotIndexingPages = [
  PageSlugs.ACCOUNT_LOYALTY,
  PageSlugs.ACCOUNT_SETTINGS,
  PageSlugs.ACCOUNT_ORDERS,
  PageSlugs.ACCOUNT_REWARDS,
  PageSlugs.CHECKOUT,
  PageSlugs.DEMO,
  // PageSlugs.CUSTOM_PAYMENT,
  PageSlugs.ACCOUNT_ACTIVATE,
  PageSlugs.PASSWORD_RESET
];

const Page = ({schemaOrg, faqSchema, breadcrumbsSchema, reviews, children}) => {
  const router = useRouter();
  const pageUrl = router.asPath.split('?')[0].substring(1);
  const {page, shop} = useStorefrontData();

  if (shop) {
    defaultPageSeo.title = shop.name;
    defaultPageSeo.seoTitle = shop.name;
    defaultPageSeo.seoDescription = shop.description;
  }

  const pageSeo = page || defaultPageSeo;

  const desktopView = useMediaQuery(theme.breakpoints.up('lg'));
  const tabletView = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));

  // trigger zopim page change event
  changeZopimPage(`${process.env.ORIGIN}/${pageUrl}`);
  return (
    <>
      <Head>
        <title>{pageSeo.seoTitle}</title>
        <meta name='application-name' content='Probemas' />
        <link
          rel='icon'
          type='image/png'
          sizes='16x16'
          href='/favicons/favicon-16x16.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='32x32'
          href='/favicons/favicon-32x32.png'
        />
        <meta name='probemas-config' content='/favicons/browserconfig.xml' />
        <link rel='manifest' href='/manifest.json' />
      </Head>

      <NextSeo
        title={pageSeo.seoTitle}
        description={pageSeo.seoDescription}
        canonical={`${process.env.ORIGIN}/${pageUrl}`}
        noindex={NotIndexingPages.includes(pageUrl)}
        nofollow={NotIndexingPages.includes(pageUrl)}
        openGraph={{
          url: `${process.env.ORIGIN}/${pageUrl}`,
          title: pageSeo.seoTitle,
          description: pageSeo.seoDescription,
          images: [{url: pageSeo.seoImage.url}],
          type: 'website',
          site_name: 'Probemas'
        }}
      />
      {schemaOrg && <SchemaOrg schema={schemaOrg} reviews={reviews} />}
      {faqSchema && page.faqItems && <FaqSchema items={page.faqItems.edges} />}
      {breadcrumbsSchema && <BreadcrumbSchema page={page} />}
      <PageContext.Provider
        value={{
          page,
          isDesktopView: desktopView,
          isTabletView: tabletView,
          isMobileView: mobileView
        }}
      >
        {/*<Header />*/}
        <ThemeProvider theme={themeNext}>
          <HeaderNext isOldPage />
        </ThemeProvider>
        <div id='content'>{children}</div>
        <Footer reviews={reviews} />
      </PageContext.Provider>
    </>
  );
};

export default Page;
