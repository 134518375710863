import gql from 'graphql-tag';

export const UPDATE_CURRENCY = gql`
  mutation UpdateCurrency($currency: String!) {
    updateCurrency(currency: $currency) @client
  }
`;

export const CaclulatePriceMutation = gql`
  mutation CalculatePrice(
    $currency: CurrencyEnum!
    $priceAmount: Decimal!
    $quantity: Int!
  ) {
    calculatePrice(
      currency: $currency
      priceAmount: $priceAmount
      quantity: $quantity
    ) {
      price {
        currency
        amount
        localized
      }
    }
  }
`;

export const CalculateLoyaltyPointsMutation = gql`
  mutation CalculateLoyaltyPoints(
    $currency: CurrencyEnum!
    $priceAmount: Decimal!
  ) {
    calculateLoyaltyPoints(currency: $currency, priceAmount: $priceAmount) {
      points
    }
  }
`;
